<template>
  <div class="columns is-centered">
    <div class="column is-5">
      <div
        class="content-box"
        :class="{
          'frosted-background-static': $isSafari,
          'frosted-background': !$isSafari,
        }"
      >
        <span style="font-size: 12px;color: #bbb;">total = {{ parties.length }}</span>

        <table class="table is-fullwidth">
          <tbody>
          <tr>
            <td>
              <router-link :to="{name: 'AdminListeDates'}">..</router-link>
            </td>
          </tr>
            <tr v-for="partie in parties">
              <td>
                <a
                  :href="
                    $url_serveur +
                    '/api/admin/bV9x2SZDFaIw4aE5a8h2obOIkapwzhEt65JyagNP/regen_export/' +
                    partie +
                    '?p=' + mdp()
                  "
                >
                  {{ partie }}
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.content-box {
  margin-top: 16px;
  border: 1px solid rgba(0, 0, 0, 0.11);
  border-radius: 12px;
  padding: 1.25rem 2.5rem 1.25rem 1.5rem;
}

.table {
  tbody {
    tr {
      td {
        font-size: 12px;
        padding: 0;

        > a {
          display: inline-block;
          width: 100%;
          height: 100%;
          padding: 3px 9px;

          cursor: pointer;
          &:hover {
            background-color: #f8f8f8;
          }
        }
      }

    }
  }
}
</style>

<script>
export default {
  name: 'ListePartiesDessins',

  data() {
    return {
      parties: [],
    };
  },

  created() {
    if (!this.$session._admin_liste_mdp) {
      this.$router.push({name: 'AdminListeDates'});
      return;
    }

    this.listeParties();
  },

  methods: {
    mdp() {
      return this.$session._admin_liste_mdp + '';
    },

    listeParties() {
      this.$axios.get(this.$url_serveur + '/api/admin/bV9x2SZDFaIw4aE5a8h2obOIkapwzhEt65JyagNP/regen_export_list/' + this.$route.params.dir, {
        params: {
          p: this.$session._admin_liste_mdp,
        }
      })
      .then((resp) => {
        this.parties = resp.data;
      });
    },
  },
}
</script>